<template lang="pug">
#pageTable
  v-container(grid-list-xl, fluid)
    v-layout(row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar")
          v-card.elevation-.pa-3
            v-flex.md4
              v-text-field(label='Nome', required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", type="text", v-model='user.nome')
            v-flex.md4
              v-text-field(label='E-mail', required, v-validate="'required|email'", :error-messages="errors.collect('email')", data-vv-name="email", type="email", v-model='user.email')
            v-flex.md4
              span.body-2 Alterar Senha 
              v-btn(icon, @click="show = !show") 
                v-icon {{ show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            v-slide-y-transition
              v-flex.md4(v-if="show")
                v-text-field(label='Senha Atual', required, v-validate="'required'", :error-messages="errors.collect('senha_atual')", data-vv-as="senha atual", data-vv-name="senha_atual", type="password", v-model='user.senha_atual')
                v-text-field(label='Nova Senha', required, v-validate="'required'", :error-messages="errors.collect('senha_nova')", data-vv-as="nova senha", data-vv-name="senha_nova", ref="senha_nova", type="password", v-model='user.senha_nova')
                v-text-field(label='Repita', required, v-validate="'required|confirmed:senha_nova'", :error-messages="errors.collect('conf_senha')", data-vv-as="repita", data-vv-name="conf_senha", type="password", v-model='user.conf_senha')
            v-flex.md4
              v-btn(color='success', type="submit", :loading='loading').pa-0.mx-0.my-0.ma-0 Salvar
</template>

<script>
import _ from 'lodash';
import Hashes from 'jshashes';
// eslint-disable-next-line
const SHA512 = new Hashes.SHA512;

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    loading: false,
    show: false,
  }),
  computed: {
    user () {
      return _.cloneDeep(this.$store.getters.user);
    }
  },
  methods: {
    async updateUser (user) {
      const vm = this;
      try {
        const response = await vm.$axios.put(`/usuario/${user.id}`, { id: user.id, nome: user.nome, email: user.email });
        vm.$store.dispatch('setUser', _.merge(user, response.data));
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Usuário salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o usuário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async updatePassword (user) {
      const vm = this;
      try {
        let usuario = _.cloneDeep(user);
        usuario.senha_atual = SHA512.hex(usuario.senha_atual);
        usuario.senha_nova = SHA512.hex(usuario.senha_nova);
        const response = await vm.$axios.put('/usuario/password', usuario);
        for (let prop in usuario) if (prop.indexOf('senha') > -1) delete usuario[prop];
        for (let prop in user) if (prop.indexOf('senha') > -1) delete user[prop];
        vm.$store.dispatch('setUser', _.merge(usuario, response.data));
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Usuário salvo com sucesso!' });
      } catch (error) {
        vm.loading = false;
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o usuário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async salvar () {
      const vm = this;
      try {
        vm.loading = true;
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.show ? 'updatePassword' : 'updateUser'](vm.user);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
  }
};
</script>

<style scoped lang="css">

</style>
